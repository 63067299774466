import React from "react"
import PathConstants from "./pathConstants"

const Home = React.lazy(() => import("../pages/home"))
const Question = React.lazy(() => import("../pages/question"))
const Hangman = React.lazy(() => import("../pages/hangman"))
const Privacy = React.lazy(() => import("../pages/privacy"))

const routes = [
    { path: PathConstants.HOME, element: <Hangman /> },
    //{ path: PathConstants.QUESTION, element: <Question /> },
    //{ path: PathConstants.HANGMAN, element: <Hangman />},
    { path: PathConstants.PRIVACY, element: <Privacy />}
]
export default routes